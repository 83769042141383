import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientInterceptor } from './interceptors/http-client.interceptor';
import { ToastComponent } from './component/toast/toast/toast.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicSlides } from '@ionic/angular';

@NgModule({
  declarations: [AppComponent,ToastComponent,],
  imports: [BrowserModule, 
    IonicModule.forRoot({
      mode: 'ios',
      scrollAssist: false,
      scrollPadding: false
    }),
  AppRoutingModule,HttpClientModule,BrowserAnimationsModule,],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {  
      provide: HTTP_INTERCEPTORS,  
      useClass: HttpClientInterceptor,  
      multi: true  
    }  ,
    ToastComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from 'src/app/core/constant';
// import { ToastComponent } from 'src/app/shared/toast/toast.component';
// import { LoadingserviceService } from '../loading/loadingservice.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Router, CanActivate } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
// import { ApiService } from '../service';
// import * as countrycitystatejson from 'countrycitystatejson';

@Injectable({
  providedIn: 'root'
})
export class AuthloginService {
  // countryData = countrycitystatejson
  public subject = new BehaviorSubject('')

  apiUrl: string = AppSettings.API_ENDPOINT;
  headers: any;
  accessToken: any;
  uploading: boolean = false;
  constructor(
    private router: Router,
    public http: HttpClient,
    // private loadingService: LoadingserviceService,
    // private toast: ToastComponent,
    // private fcm:FCM,
    // private ApiService:ApiService,
    private platform: Platform

  ) {
    this.headers = new Headers();
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept', 'application/json');
    this.headers.append('content-type', 'application/json');
    ;
  }
  // signUp(data: any, model: any) {
  //   console.log
  //   const { body} =  data;
  //   return this.ApiService.request({
  //     path:this.apiUrl + model,
  //     method:"POST",
  //     body
  //   });
  // }

  raiseEvent(e: string) {
    this.subject.next(e);
  }
  canActivate(): any {

    if (localStorage.getItem("token")) {
      this.router.navigate(['/home']);
      return false;

    }
    else {
      return true;
    }
  }
  login(data: any, model: any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model;
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {
          console.log(err, 'err')
          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }
  add(data: any, model: any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model;
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {

          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }
  otpVerify(data: any, model: any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model;
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {
          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }
  getById(id: any, model: any) {
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model + '/' + id;
    return new Promise((resolve, reject) => {

      this.http.get(addRatingURL, { headers: headers })
        .subscribe(res => {

          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {

          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });



  }
  userProfile(data: any, id: any, model: any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model + '/' + id;
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {
          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }
  logout() {
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',

      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''
    }
    );
    // this.loadingService.showLoading();  
    let logouturl = this.apiUrl + 'logout';
    return new Promise((resolve, reject) => {
      this.http.get(logouturl, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          localStorage.removeItem("token");
          localStorage.removeItem('userData');
          localStorage.removeItem('sessionToken');
          resolve(res);
        }, (err) => {
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });

  }
  get(model: any) {
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',

      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''
    }
    );
    // this.loadingService.showLoading();  
    let logouturl = this.apiUrl + model;
    return new Promise((resolve, reject) => {
      this.http.get(logouturl, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();

          resolve(res);
        }, (err) => {
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });

  }
  search(model: any, filter: any) {
    let filterJson = encodeURI(JSON.stringify(filter));

    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',

      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''
    }
    );
    // this.loadingService.showLoading();  
    let logouturl = this.apiUrl + model+ '?filter=' + filterJson;
    return new Promise((resolve, reject) => {
      this.http.get(logouturl, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();

          resolve(res);
        }, (err) => {
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });


  }
  // getCountries() {
  // 	return this.countryData.getCountries();
  //   }

  //   getStatesByCountry(countryShotName: string) {
  // 	return this.countryData.getStatesByShort(countryShotName);
  //   }

  //   getCitiesByState(country: string, state: string) {
  // 	return this.countryData.getCities(country, state);
  //   }
  edit(data: any, model: any,id:any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model + '/' + id;
    return new Promise((resolve, reject) => {

      this.http.patch(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {

          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }

  AccepFriendEdit(data: any, model: any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model;
    return new Promise((resolve, reject) => {

      this.http.patch(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {

          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }

  deleteFriend(model:any){
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model;
    return new Promise((resolve, reject) => {

      this.http.patch(addRatingURL, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {

          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }

  addFriend(data: any, model: any,id:any) {
    console.log(data, 'data');
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',
      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''

    });

    console.log(headers, 'headers')

    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model + '/' + id;
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {

          // this.loadingService.hideLoading();
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });

    });

  }
  deleteGroup(model:any,id:any){
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',

      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''
    }
    );
    // this.loadingService.showLoading();  
    let logouturl = this.apiUrl + model + '/' + id;
    return new Promise((resolve, reject) => {
      this.http.delete(logouturl, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();

          resolve(res);
        }, (err) => {
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });


  }

  delete(model:any){
    const sessionToken: any = localStorage.getItem('sessionToken');

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorizationToken': localStorage.getItem('token') || '',

      'Authorization': localStorage.getItem('sessionToken') ? 'Bearer ' + JSON.parse(sessionToken).token : ''
    }
    );
    // this.loadingService.showLoading();  
    let url = this.apiUrl + model;
    return new Promise((resolve, reject) => {
      this.http.delete(url, { headers: headers })
        .subscribe(res => {
          // this.loadingService.hideLoading();

          resolve(res);
        }, (err) => {
          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });


  }
}


import { Injectable } from '@angular/core';
import { ToastComponent } from '../component/toast/toast/toast.component';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
   } from '@angular/common/http';
   import { Observable, throwError } from 'rxjs';
   import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { ToastComponent } from '../shared/toast/toast.component';
// import { CustomerInfoService } from '../services/smr/customer-info/customer-info.service';
// import { MatDialog } from '@angular/material/dialog';
// import { ShowSuccessErrorPopupComponent } from '../modules/smr/customer-home-page/components/show-success-error-popup/show-success-error-popup.component';

@Injectable({
  providedIn: 'root'
})

export class HttpClientInterceptor implements HttpInterceptor {

  constructor(
    private toast : ToastComponent,
    private readonly router:Router,
    // public dialog: MatDialog,
    // private toast:ToastComponent,private customerInfoService: CustomerInfoService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse |any) => {
        
        if(error && error.status==401){
        //   this.customerInfoService.toast.snackbarSucces("Session timeout");
          window.localStorage.clear();
          window.location.reload();
          this.router.navigate(['/login']);
        } 
        return throwError(()=>{("api error")});
      })
    );
  }
}

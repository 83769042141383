import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { AuthloginService } from 'src/services/authlogin/authlogin.service';

const routes: Routes = [
  {
    path: '',
    canActivate:[AuthenticatedGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'reward/:id',
    canActivate:[AuthenticatedGuard],
    loadChildren: () => import('./group-details/group-details.module').then( m => m.GroupDetailsPageModule),
  },
  
  {
    
    path: 'login',
    canActivate: [AuthloginService],
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchModule)
  },
  {
    
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    // canActivate: [AuthloginService],
  },
  {
    
    path: 'otp',
    // canActivate: [AuthloginService],
    loadChildren: () => import('./otp/otp.module').then( m => m.OtpPageModule)
  },

  
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    // canActivate: [AuthenticatedGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'new-profile',
    loadChildren: () => import('./new-profile/new-profile.module').then( m => m.NewProfilePageModule)
  },
  {
    path: 'new-edit-profile',
    loadChildren: () => import('./new-edit-profile/new-edit-profile.module').then( m => m.NewEditProfilePageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'edit-address',
    loadChildren: () => import('./edit-address/edit-address.module').then( m => m.EditAddressPageModule)
  },
  {
    path: 'group',
    loadChildren: () => import('./group/group.module').then( m => m.GroupPageModule)
  },
  {
    path: 'add-member',
    loadChildren: () => import('./add-member/add-member.module').then( m => m.AddMemberPageModule)
  },
  {
    path: 'group-details',
    loadChildren: () => import('./group-details/group-details.module').then( m => m.GroupDetailsPageModule)
  },
  {
    path: 'home-new',
    loadChildren: () => import('./home-new/home-new.module').then( m => m.HomeNewPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  // {
  //   path: 'add-group',
  //   loadChildren: () => import('./add-group/add-group.module').then( m => m.AddGroupPageModule)
  // },
  {
    path: 'group-notification',
    loadChildren: () => import('./group-notification/group-notification.module').then( m => m.GroupNotificationPageModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./component/header/header.module').then( m => m.HeaderPageModule)
  },  {
    path: 'notification-popup',
    loadChildren: () => import('./notification-popup/notification-popup.module').then( m => m.NotificationPopupPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
